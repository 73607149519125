import React from "react";

import "./assets/styles/default.css";

import Header from "./components/Header";
import About from "./components/About";
import Intro from "./components/Intro";
import Tech from "./components/Tech";
import Projects from "./components/Projects";

function App() {
	window.addEventListener("load", function () {
		setTimeout(function() {
			document.getElementById("preload").style.visibility = "hidden";
			document.getElementById("preload").style.opacity = 0;
		}, 1000);
	});

	return (
		<>
			<span id="preload"></span>
			<Header />
			<main style={{
					"margin": "0 clamp(2.5rem, 0.331rem + 10.84vw, 8.125rem)",
					"display": "flex",
					"flexDirection": "column",
					"gap": "clamp(6.25rem, 3.595rem + 13.27vw, 15.625rem)"
				}}>
				<Intro />
				<About />
				<Tech />
				<Projects />
			</main>
		</>
	);
}

export default App;
