import React, { useState } from "react";

import "../assets/styles/header.css";

function Header() {
    const [languageShowState, LanguageShow] = useState("");
    const [burgerShowState, BurgerShow] = useState("");
    const [navShowState, NavShow] = useState("");

    function languageListShow() {
        languageShowState === "show-langs" ? LanguageShow("") : LanguageShow("show-langs");
    }
    
    function showBurger() {
        burgerShowState === "burger-active" ? BurgerShow("") : BurgerShow("burger-active");
        navShowState === "active-nav" ? NavShow("") : NavShow("active-nav");
        document.body.style.overflow = burgerShowState === "burger-active" ? "auto" : "hidden";
    }
    return (
        <header className="header">
            <a href="#top">
                <img src={process.env.PUBLIC_URL + "/images/portfolio-logo.svg"} alt="Logo" className="header__logo"/>
            </a>
            <nav className={"header__navbar " + navShowState}>
                <ul className="header__nav">
                    <li className="header__nav-item">
                        <a href="#about" className="header__nav-link"><code>&lt;About /&gt;</code></a>
                    </li>
                    <li className="header__nav-item">
                        <a href="#stack" className="header__nav-link"><code>&lt;Technologies /&gt;</code></a>
                    </li>
                    <li className="header__nav-item">
                        <a href="#projects" className="header__nav-link"><code>&lt;Projects /&gt;</code></a>
                    </li>
                    <li className="header__nav-item">
                        <a href="#price" className="header__nav-link"><code>&lt;Price /&gt;</code></a>
                    </li>
                    <li className="header__nav-item">
                        <img src={process.env.PUBLIC_URL + "/images/icons/language.svg"} alt="language-switcher" className="language-switcher" onClick={languageListShow} />
                        <ul className={"header__subnav " + languageShowState}>
                            <li className="header__subnav-item">KZ</li>
                            <hr />
                            <li className="header__subnav-item">EN</li>
                            <hr />
                            <li className="header__subnav-item">RU</li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div className={"burger-menu " + burgerShowState} onClick={showBurger}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </header>
    )
}

export default Header;