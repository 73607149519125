import React from "react";

import "../assets/styles/projects.css";

function Projects() {
    return (
        <section className="projects">
            <h2 className="projects__title title"><code>&lt;Projects&gt;</code></h2>
            <div className="projects__block">
                <img src="" alt="" className="projects__img" />
                <article className="projects__article">
                    <h3 className="projects__title title">JAŃABASPA</h3>
                    <p className="projects__text text">JAŃABASPA this is manga reader website for kazakhstan made/translated titles. Developed with React using Firebase.</p>
                </article>
            </div>
        </section>
    )
}

export default Projects;