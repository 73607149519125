import React from "react";

import "../assets/styles/tech.css";

function Tech() {
    var year = parseInt(new Date().getFullYear()) - 2020;
    return (
        <section className="tech">
            {/* <div className="tech__list">
                <div className="tech__block">
                    <p className="tech__item subtitle">Languages</p>
                    <p className="tech__item text">JavaScript, TypeScript</p>
                    <p className="tech__item text">Python</p>
                    <p className="tech__item text">SQL</p>
                </div>
                <div className="tech__block">
                    <p className="tech__item subtitle">Frameworks</p>
                    <p className="tech__item text">React</p>
                    <p className="tech__item text">Django</p>
                    <p className="tech__item text">Bootstrap</p>
                </div>
                <div className="tech__block">
                    <p className="tech__item subtitle">Libraries</p>
                    <p className="tech__item text">Redux</p>
                    <p className="tech__item text">Django Rest</p>
                    <p className="tech__item text">Firebase</p>
                </div>
                <div className="tech__block">
                    <p className="tech__item subtitle">Tools</p>
                    <p className="tech__item text">GitHub</p>
                    <p className="tech__item text">VS Code</p>
                    <p className="tech__item text">HTML, CSS, SCSS</p>
                </div>
            </div> */}
            <div className="tech__list">
                <ul className="tech__block">
                    <li className="tech__item text">JavaScript, TypeScript</li>
                    <li className="tech__item text">React</li>
                    <li className="tech__item text">Bootstrap</li>
                    <li className="tech__item text">Firebase</li>
                    <li className="tech__item text">GitHub</li>
                    <li className="tech__item text">Python</li>
                </ul>
                <ul className="tech__block">
                    <li className="tech__item text">HTML, CSS, SCSS</li>
                    <li className="tech__item text">VS Code</li>
                    <li className="tech__item text">Django</li>
                    <li className="tech__item text">Redux</li>
                    <li className="tech__item text">SQL</li>
                    <li className="tech__item text">Django Rest</li>
                </ul>
            </div>
            <article className="tech__article">
                <h2 className="tech__title title"><code>&lt;Technologies className="skills"&gt;</code></h2>
                <p className="tech__text text">
                    Here is a fine list of my knowledge. As I said above I'm exploring programming already {year} years. Big fan of React framework, also interested in Python. I'm not afraid of hard work and always ready to study something new.
                    </p>
                <h2 className="tech__title title"><code>&lt;/Technologies&gt;</code></h2>
            </article>
        </section>
    );
}

export default Tech;