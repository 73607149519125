import React from "react";

import "../assets/styles/about.css";

function About() {
    return (
        <section className="about" id="about">
            <article className="about__article">
                <h2 className="about__title title">
                    <code>
                        &lt;section <i style={{"fontFamily": "Comic Sans MS"}}>className</i>="about"&gt;
                    </code>
                </h2>
                <p className="about__text text">
                    Hello, my name again Alibek, I enjoy creating things that live on the <strong>internet</strong>, whether that be <strong>websites, applications, or anything in between</strong>. My goal is to always build products that provide pixel-perfect, performant experiences. I have been working on web development since mid-<time dateTime="2020">2020</time>.
                    <br/><br/>
                    I'am well organized, problem solver, responsible person also have a good time management. My main goal is not to become a <strong>professional web developer</strong>, it is constantly improve my skills and learn new technologies which will help me to become a better than professional developer. 
                </p>
                <h2 className="about__title title">
                    <code>
                        &lt;/section&gt;
                    </code>
                </h2>
            </article>
            <img src={process.env.PUBLIC_URL + "/images/about.webp"} alt="about" className="about__pic"/>
        </section>
    )
}

export default About;