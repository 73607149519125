import React from "react";

import "../assets/styles/intro.css";

function Intro() {
    return (
        <>
            <section className="intro">
                <article className="intro__article">
                    <h2 className="intro__subtitle subtitle">Hello, my name is<p className="intro__name">Alibek</p></h2>
                    <h1 className="intro__title title">I create stuff for web and for your projects</h1>
                    <p className="intro__text text">
                        <strong>Web devoloper</strong> with experience of work in<br/>
                        <code>&lt;list of technologies /&gt;</code> and skills in <code>&lt;list of skills /&gt;</code>
                        {/* I enjoy working on a variety of projects and am always looking for new challenges. Feel free to contact me for any potential projects or collaborations */}
                    </p>
                </article>
                
                <section className="intro__picBox">
                    <img src={process.env.PUBLIC_URL + "/images/code.webp"} alt="Code" className="intro__codePic"/>
                    <div className="intro__picOutline"></div>
                </section>
            </section>
        </>
    )
}

export default Intro;